import React from "react";
import { AuthenticatedBuyer } from "../../../layout/AuthenticatedBuyer";
import { data } from "../../Homepage/LatestProducts";

function Dashboard(props) {
  return (
    <>
      <AuthenticatedBuyer {...props}>
        <div className="container-fluid p-0">
          <div className="row mt-5">
            <div className="col-lg-6">
              <ol class="breadcrumb bg-dark">
                <li class="breadcrumb-item">
                  <a href="#">Dashboard</a>
                </li>
                <li class="breadcrumb-item text-white">Welcome, TREYDA INC.</li>
              </ol>
            </div>

            <div className='col-lg-6'>
              <input type='search' className='w-100 pl-3 pr-3' placeholder='Search'/>
            </div>
          </div>
        </div>

        <h4 className="font-weight-bolder">MATCHES</h4>

        <div className="container-fluid p-0">
          <div className="row">
            {data.map((res, i) => {
              return (
                <div
                  className="col-lg-2 col-sm-12 col-xs-12 col-md-12 text-center p-3"
                  key={i}
                  data-aos="fade-up"
                >
                  <div className="p-3">
                    <img
                      src={res.image}
                      alt={res.name}
                      height="100"
                      width="100"
                    />
                    <h4 className="font-weight-bolder ">{res.name}</h4>

                    <div className="row">
                      <div className="col-lg-4 col-sm-4 col-xs-4 text-left">
                        <small className=" d-block">Supplier:</small>
                        <small className=" d-block">In stock:</small>
                        <small className="d-block">Port:</small>
                        <small className="d-block">Variety:</small>
                        <small className="d-block">Terms:</small>
                      </div>
                      <div className="col-lg-8 col-sm-8 col-xs-8 text-left">
                        <small className="d-block font-weight-bolder">
                          {res.supplier}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.quantitiy}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.port}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.variety}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.terms}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="container-fluid p-0">
            <div className="row mb-5">
              <div className="col-lg-7">
                <div className="pap" style={{ height: "300px" }}></div>
              </div>
              <div className="col-lg-5">
                <div className="pap" style={{ height: "300px" }}></div>
              </div>
            </div>
          </div>
        </div>
      </AuthenticatedBuyer>
    </>
  );
}

export default Dashboard;
