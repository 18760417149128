import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
//import {createLogger} from 'redux-logger';
import  rootReducer from '../reducers/index';


//const logger= createLogger();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;