import React, {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";


export const CustomPagination = (props) => {
    const [activeItem, setActiveItem] = useState(1);
    const { totalPages = 5, size = "md", withIcons = true, disablePrev = false } = props;
  
    const onPrevItem = () => {
      const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
      setActiveItem(prevActiveItem);
    };
  
    const onNextItem = (totalPages) => {
      const nextActiveItem = activeItem === totalPages ? activeItem : activeItem + 1;
      setActiveItem(nextActiveItem);
    };
  
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      const isItemActive = activeItem === number;
  
      const handlePaginationChange = () => {
        setActiveItem(number);
      };
 
      items.push(
        <span active={isItemActive} key={number} className={isItemActive ? 'bg-danger':'bg-success'} onClick={handlePaginationChange}>
          {number}
        </span>
      );
    };
  
    return (
      <ul size={size} className="mt-3">
        <span disabled={disablePrev} onClick={onPrevItem}>
          {withIcons ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : "Previous"}
        </span>
        {items}
        <span onClick={() => onNextItem(totalPages)}>
          {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
        </span>
      </ul>
    );
  };