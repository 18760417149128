import React from "react";
import { Footer } from "../../components/Footer";
import { Navigation } from "../../components/Navigation";
///import { LatestProducts } from './LatestProducts';
import { data } from "./LatestProducts";
import Chart from "react-google-charts";

export const SellerHomepage = () => {
  return (
    <div>
      <Navigation />

      <div className="banner-bg2"> 
            <div className="hero-text">
              <div>
                <h1 className="font-weight-bolder text-center mt-4">
                  {" "}
                  Your Agribusiness marketplace in Africa
                </h1>
                <h4>
                  New and existing trade relationships do not have to be slow,
                  uncertain, time-consuming.
                </h4>
              </div>
              <img
            src="/images/logo-white.png"
            alt="logo"
          />


              <div className="container">
                <div className="row">
                  <div className="col-lg-1"> </div>
                  <div className="col-lg-10 col-lg-10 text-dark p-3 round-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className=" h-100 p-4">
                          <h5 className="font-weight-bolder text-center text-white ">
                          <i class="fa fa-building" style={{fontSize:'30px'}}></i> {" "}
                            Company Infomation
                          </h5>

                          <p className="text-left m-0 mt-3 text-white ">Company Mail</p>
                          <input
                            placeholder="Your company email address"
                            type="email"
                            className="w-100 p-2"
                          />

                          <p className="text-left m-0 mt-3 text-white ">Company Name</p>
                          <input
                            placeholder="Your company name"
                            type="text"
                            className="w-100 p-2"
                          />

                          <p className="text-left m-0 mt-3 text-white ">Company Address</p>
                          <input
                            placeholder="Your company address"
                            type="text"
                            className="w-100 p-2"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="h-100 p-4">
                          <h5 className="font-weight-bolder text-center text-white ">
                            Company Details
                          </h5>
                          <p className="text-left m-0 mt-3 text-white ">Country / City</p>
                          <select className="w-100 p-2">
                            <option>
                              --- Country(Must be within Africa) ---{" "}
                            </option>
                            <option>Nigeria</option>
                            <option>Ghana</option>
                            <option>Gabon</option>
                            <option>Cote d'Ivoire</option>
                          </select>

                          <p className="text-left m-0 mt-3 text-white ">CEO's name</p>
                          <input
                            type="text"
                            placeholder="CEO's name"
                            className="w-100 p-2"
                          />
                          <div className="mt-4">
                            <button className="treyda-button btn w-50">
                              REGISTER
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"> </div>
                </div>
              </div>
        </div>
       
      </div>

      <section className="mt-5 mb-5 pt-5">
        <h2 className="font-weight-bolder text-center">Latest Products</h2>

        {/* <LatestProducts /> */}

        <div className="container-fluid pl-5 pr-5">
          <div className="row align-items-center">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                {data.map((res, i) => {
                  return (
                    <div
                      className="col-lg-4 col-sm-6 col-xs-6 text-center p-3"
                      key={i}
                      data-aos="fade-up"
                    >
                      <div className="p-3 pap">
                        <img src={res.image} alt={res.name} height="100" />
                        <h4 className="font-weight-bolder ">{res.name}</h4>
                        {/* <h6>{res.company}</h6> */}
                        <p style={{ fontSize: "13px" }}>{res.description}</p>
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-xs-4 text-left">
                            <small className=" d-block">Supplier:</small>
                            <small className=" d-block">In stock:</small>
                            <small className="d-block">Port:</small>
                            <small className="d-block">Variety:</small>
                            <small className="d-block">Terms:</small>
                          </div>
                          <div className="col-lg-8 col-sm-8 col-xs-8 text-left">
                            <small className="d-block font-weight-bolder">
                              {res.supplier}
                            </small>
                            <small className="d-block font-weight-bolder">
                              {res.quantitiy}
                            </small>
                            <small className="d-block font-weight-bolder">
                              {res.port}
                            </small>
                            <small className="d-block font-weight-bolder">
                              {res.variety}
                            </small>
                            <small className="d-block font-weight-bolder">
                              {res.terms}
                            </small>
                          </div>
                        </div>

                        <p
                          style={{ fontSize: "13px" }}
                          className="font-weight-bolder mt-3"
                        >
                          {res.price}/Metric Tonne
                        </p>

                        <a href="https://wa.me/+4917662388466?text=I'm%20interested%20in%20one%20of%20your%20product"> <button className="btn treyda-button btn-sm font-weight-bolder">
                       <i className="fa fa-whatsapp"></i> +49 176 62388466 {/* View Details */}
                        </button> </a>
                      </div>
                    </div>
                  );
                })}
                <div className="col-lg-12 text-center mt-3">
                  <button className="btn treyda-button btn-lg font-weight-bolder">
                    SEE ALL PRODUCTS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-5 text-center" data-aos="fade-up">
        <h2 className="font-weight-bolder text-center">
          Geographical statistics of Agricultural Produce{" "}
        </h2>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <Chart
                //width={'500px'}
                // height={'300px'}
                chartType="GeoChart"
                data={[
                  ["Country", "Yam", "Beans"],
                  ["Algeria", 36, 36],
                  ["Angola", 8, 8],
                  ["Benin", 6, 66],
                  ["Botswana", 4, 67],
                  ["Burkina Faso", 12, 66],
                  ["Burundi", 39, 88],
                  ["Cameroon", 3, 6],
                  ["Canary Islands", 28, 78],
                  ["Cape Verde", 15, 98],
                  ["Central African Republic", 4, 90],
                  ["Ceuta", 35, 98],
                  ["Chad", 12, 78],
                  ["Comoros", 12, 89],
                  ["Cote d'Ivoire", 6, 89],
                  ["Democratic Republic of the Congo", 3, 6],
                  ["Djibouti", 12, 34],
                  ["Egypt", 26, 34],
                  ["Equatorial Guinea", 3, 78],
                  ["Eritrea", 15, 5],
                  ["Ethiopia", 9, 88],
                  ["Gabon", 0, 10],
                  ["Gambia", 13, 20],
                  ["Ghana", 5, 11],
                  ["Guinea", 10, 50],
                  ["Guinea-Bissau", 12, 34],
                  ["Kenya", 1, 78],
                  ["Lesotho", 29, 34],
                  ["Liberia", 6, 12],
                  ["Libya", 32, 34],
                  ["Madagascar", 6, 19],
                  ["Malawi", 14, 343],
                  ["Mali", 12, 205],
                  ["Mauritania", 18, 45],
                  ["Mauritius", 20, 56],
                  ["Mayotte", 13, 2],
                  ["Melilla", 35, 19],
                  ["Morocco", 32, 190],
                  ["Mozambique", 25, 45],
                  ["Namibia", 22, 42],
                  ["Niger", 14, 8],
                  ["Nigeria", 8, 12],
                  ["Republic of the Congo", 1, 89],
                  ["Réunion", 21, 8],
                  ["Rwanda", 2, 78],
                  ["Saint Helena", 16, 44],
                  ["São Tomé and Principe", 0, 45],
                  ["Senegal", 15, 12],
                  ["Seychelles", 5, 123],
                  ["Sierra Leone", 8, 7],
                  ["Somalia", 2, 34],
                  ["Sudan", 15, 56],
                  ["South Africa", 30, 56],
                  ["South Sudan", 5, 45],
                  ["Swaziland", 26, 2],
                  ["Tanzania", 6, 12],
                  ["Togo", 6, 12],
                  ["Tunisia", 34, 45],
                  ["Uganda", 1, 12],
                  ["Western Sahara", 25, 89],
                  ["Zambia", 15, 12],
                  ["Zimbabwe", 18, 12],
                ]}
                options={{
                  region: "002", // Africa
                  colorAxis: { colors: ["green"] },
                  backgroundColor: "#fff",
                  datalessRegionColor: "#ccc",
                  defaultColor: "black",
                }}
                // Note: you will need to get a mapsApiKey for your project.
                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                mapsApiKey="AIzaSyBUc3XN7aqk3ie3W7l7AKkFcvLmkHKx3bQ"
                rootProps={{ "data-testid": "4" }}
              />
              <small className="font-weight-bolder">
                This is still a dummy map. The live data will be integrated in
                the coming days
              </small>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-5">
        <h2 className="font-weight-bolder text-center mb-5">
          What You Can Do at Treyda
        </h2>
        <div className="container-fluid pl-5 pr-5 mb-5">
          <div className="row align-items-center">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-4">
                  <img
                    src="/images/verified.jpg"
                    alt="buying"
                    style={{ width: "100%", height: "200px" }}
                  />
                  <h4 className="font-weight-bolder mt-3">
                  <i class="fa fa-certificate" style={{fontSize:'30px'}}></i> {" "}
                    Verified Suppliers and Buyers
                  </h4>
                  <h5 className="mt-4">
                    Discover global sales opportunities
                  </h5>
                  <p>
                    Hundreds of suppliers of Agricultural crops in Africa
                    servicing buyers (traders; food manufacturers; mass
                    retailers; wholesalers and distributors).
                  </p>
                </div>
                <div className="col-lg-4">
                  <img
                    src="/images/funding.jpg"
                    alt="buying"
                    style={{ width: "100%", height: "200px" }}
                  />
                  <h4 className="font-weight-bolder mt-3">
                  <i class="fa fa-dollar" style={{fontSize:'30px'}}></i> {" "}
                    Access funding for Suppliers
                  </h4>
                  <h5 className="mt-4">
                    Discover global funding providers
                  </h5>
                  <p>
                    We are working on features to allow our suppliers to connect
                    seamlessly to our financial institutions network and apply
                    for funding in Trade finance; Commercial loans; Equipment
                    Financing; Private Equity
                  </p>
                </div>

                <div className="col-lg-4">
                  <img
                    src="/images/suppliers.jpg"
                    alt="buying"
                    style={{ width: "100%", height: "200px" }}
                  />
                  <h4 className="font-weight-bolder mt-3">
                  <i class="fa fa-search" style={{fontSize:'30px'}}></i> {" "}
                    Buyers find matching suppliers
                  </h4>
                  <h5 className="mt-4">
                    Discover global sourcing opportunities
                  </h5>
                  <p>
                    We understand that finding a new and reliable supplier in
                    quality and quantity is difficult. Treyda offers a wide
                    range of vetted suppliers ready to match your needs
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
    </div>
  );
};
