import React from "react";

export const Footer = () => {
  return (
    <div className="p-2 text-white " style={{ background: "#262E39" }}>
      <div className="container-fluid pl-5  mt-3">
        <div className="row ">
          <div className="col-lg-3">
            <h4 className="font-weight-bolder">Useful Links</h4>
            <p>FAQ</p>
            <p><a className='text-decoration-none text-white' href="/images/Treyda.pdf" target="_blank" rel="noreferrer">Invest In Treyda</a></p>
            <p>Services</p>
            <p>Pricing</p>
            <p>Market Insights</p>
            <p>Information</p>
          </div>
          <div className="col-lg-3">
            <h4 className="font-weight-bolder">Contact Us</h4>
            <div>
              <i class="fa fa-address-card"></i>
              <span className="ml-2"> Treyda Address One</span>
            </div>

            <div className="mt-4">
              <i class="fa fa-address-card"></i>
              <span className="ml-2"> Treyda Address Two</span>
            </div>

            <div className="mt-4">
              <i class="fa fa-envelope"></i>
              <span className="ml-2"> info@treyda.com</span>
            </div>

            <div className="mt-4">
              <i className="fa fa-phone"></i>
              <span className="ml-2"> Contact Line</span>
            </div>
          </div>
          <div className="col-lg-3">
            <h4 className="font-weight-bolder">Find Us</h4>

            <div className="mt-3">
              <i className="fa fa-facebook"></i>
              <span className="ml-2">@Treyda</span>
            </div>

            <div className="mt-3">
              <i className="fa fa-instagram"></i>
              <span className="ml-2">@Treyda</span>
            </div>

            <div className="mt-3">
              <i className="fa fa-twitter"></i>
              <span className="ml-2">@Treyda</span>
            </div>

            <div className="mt-3">
              <i className="fa fa-linkedin"></i>
              <span className="ml-2">@Treyda</span>
            </div>
          </div>

          <div className="col-lg-3">
            <h4 className="font-weight-bolder">Terms & Policies</h4>
            <p>
              <a href="/t&c" className="text-decoration-none text-white">
                Terms & Conditions
              </a>
            </p>
            <p>
              <a
                href="/code-of-ethics"
                className="text-decoration-none text-white"
              >
                Supplier code of ethics
              </a>
            </p>
            <p>
              <a
                href="/privacy-policy"
                className="text-decoration-none text-white"
              ></a>
              Privacy Policy
            </p>
            <p>
              {" "}
              <a
                href="/cookies-policy"
                className="text-decoration-none text-white"
              >
                Cookies Policy
              </a>
            </p>
            <img src="https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F22757070-190e-42b7-ba7d-78545c5eacc3%2FTQ_Member_Badge_1.png?table=block&id=1e3c68a9-b15f-465e-a7ed-7a0a983b9f84&spaceId=82544c4e-7e0c-4b87-be2b-8382b4973361&width=820&userId=81e77349-dea1-4995-95b1-d359ce4a8d4b&cache=v2" width="70%" height="25%"></img>

          </div>
        </div>
      </div>
      <hr className="bg-white" />
      <h5 className="text-center"> © 2021 Treyda</h5>
    </div>
  );
};
