import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LoaderButton from "../../../components/LoaderButton";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { updateSeller } from "../../../services/onboarding/onboardingService";
import { onError, onSuccess } from "../../../libs/errorLib";
import { history } from "../../../App";

export const StepThree = () => {
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({
        director_one_name: "",
        director_one_phone_number: "",
        director_one_email: "",
        director_one_address: "",
        director_one_passport: "",
        director_two_name: "",
        director_two_phone_number: "",
        director_two_email: "",
        director_two_address: "",
        director_two_passport: "",
    });

    function handleFieldChange(e) {
        const { name, value } = e.target;
        setFields((fields) => ({ ...fields, [name]: value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        let response;
        response = await updateSeller(fields);
        setIsLoading(false);
        if (response.ok) {
            onSuccess(`${response?.data.response_message || "Successful"}`);
            history.push("/onboarding/seller/step-four");
        } else {
            onError(`${response?.data.response_message || "Something went wrong"}`);
            setIsLoading(false);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-9 mx-auto">
                    <div className="box p-3 mb-5">
                        <Form onSubmit={handleSubmit}>
                            <div className="container p-0">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director one name
                    </p>
                                        <input
                                            className="w-100"
                                            type="text"
                                            name="director_one_name"
                                            value={fields.director_one_name}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director one name"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director one phone number
                    </p>
                                        <input
                                            className="w-100"
                                            type="number"
                                            name="director_one_phone_number"
                                            value={fields.director_one_phone_number}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director one phone number"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director one email
                    </p>
                                        <input
                                            className="w-100"
                                            type="email"
                                            name="director_one_email"
                                            value={fields.director_one_email}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director one email"
                                        />
                                    </div>

                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director one address
                                        </p>
                                        <input
                                            className="w-100"
                                            type="text"
                                            name="director_one_address"
                                            value={fields.director_one_address}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director one address"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director one passport
                    </p>
                                        <input
                                            className="w-100"
                                            type="text"
                                            name="director_one_passport"
                                            value={fields.director_one_passport}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director one passport"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director two name
                    </p>
                                        <input
                                            className="w-100"
                                            type="text"
                                            name="director_two_name"
                                            value={fields.director_two_name}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director two name"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director two phone number
                    </p>
                                        <input
                                            className="w-100"
                                            type="number"
                                            name="director_two_phone_number"
                                            value={fields.director_two_phone_number}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director two phone number"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director two email
                    </p>
                                        <input
                                            className="w-100"
                                            type="email"
                                            name="director_two_email"
                                            value={fields.director_two_email}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director two email"
                                        />
                                    </div>

                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director two address
                                        </p>
                                        <input
                                            className="w-100"
                                            type="text"
                                            name="director_two_address"
                                            value={fields.director_two_address}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director two address"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <p className="text-left m-0 mt-3 mb-2 text-dark">
                                            Director two passport
                    </p>
                                        <input
                                            className="w-100"
                                            type="text"
                                            name="director_one_passport"
                                            value={fields.director_two_passport}
                                            onChange={handleFieldChange}
                                            placeholder="Please enter your director two passport"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <LoaderButton
                                    block
                                    size="lg"
                                    className="treyda-button btn w-50"
                                    type="submit"
                                >
                                    SAVE CHANGES
                </LoaderButton>
                            </div>
                        </Form>
                    </div>
                </div>

                <div className="col-lg-2">
                    <h5 className="font-weight-bolder">TRUST SCORE</h5>
                    <CircularProgressbar value={progress} text={`${progress}%`} />;
        </div>
            </div>
        </div>
    );
};
