import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { onError, onSuccess } from "../../libs/errorLib";
import "../../css/signUp.css";
import { history } from "../../App";
import { Navigation } from "../../components/Navigation";
import { signUp } from "../../services/signUp/signUp";
import { Footer } from "../../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoadingOverlay from "react-loading-overlay";
import { CustomPagination } from "./CustomPagination";

export default function Signup() {
  const [selected, setSelected] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    country_name: "",
    country_code: "",
    user_type: "",
  });

  useEffect(() => {
    setFields({
      first_name: fields.first_name,
      last_name: fields.last_name,
      email: fields.email,
      phone_number: fields.phone_number,
      password: fields.password,
      country_name: fields.country_name,
      country_code: fields.country_code,
      user_type: fields.user_type,
    });
  }, [selected]);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === confirmPassword
    );
  }

  function handleFieldChange(e) {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  }

  const handleSubmit = async (event) => {
    localStorage.setItem("email", fields.email);
    event.preventDefault();
    setIsLoading(true);
    let response;
    response = await signUp(fields);
    setIsLoading(false);
    if (response.ok) {
      history.push("/verify-email");
      onSuccess(`${response?.data.response_message || "Successful"}`);
    } else {
      onError(`${response?.data.error || "Something went wrong"}`);
      setIsLoading(false);
    }
  };

  function renderForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-6">
              <p className="text-left m-0 mt-4 mb-2 text-dark">First Name</p>
              <input
                className="w-100"
                type="text"
                name="first_name"
                value={fields.first_name}
                onChange={handleFieldChange}
                placeholder="Please enter your first name"
              />
            </div>
            <div className="col-lg-6">
              <p className="text-left m-0 mt-4 mb-2 text-dark">Last Name</p>
              <input
                className="w-100"
                type="text"
                name="last_name"
                value={fields.last_name}
                onChange={handleFieldChange}
                placeholder="Please enter your last name"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-6">
              <p className="text-left m-0 mt-4 mb-2 text-dark">Email</p>
              <input
                className="w-100"
                type="email"
                name="email"
                value={fields.email}
                onChange={handleFieldChange}
                placeholder="Please enter a valid email address"
              />
            </div>
            <div className="col-lg-6">
              <p className="text-left m-0 mt-4 mb-2 text-dark">Phone Number</p>
              <PhoneInput
                country={"ng"}
                enableSearch
                value={selected}
                onChange={(value, country, e, formattedValue) =>
                  setFields((felds) => ({
                    ...fields,
                    country_name: country.name,
                    country_code: country.dialCode,
                    phone_number: value,
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-6">
              <p className="text-left m-0 mt-4 mb-2 text-dark">Password</p>
              <input
                className="w-100"
                type="password"
                name="password"
                value={fields.password}
                onChange={handleFieldChange}
                placeholder="Password"
              />
            </div>
            <div className="col-lg-6">
              <p className="text-left m-0 mt-3 mb-2 text-dark">
                Confirm password
              </p>
              <input
                className="w-100"
                type="password"
                name="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                placeholder="Confirm Password"
              />
            </div>
          </div>
        </div>

        <p className="text-left m-0 mt-4 mb-2 text-dark">I am a :</p>
        <select
          className="w-100"
          value={fields.user_type}
          onChange={handleFieldChange}
          name="user_type"
        >
          <option value="">---Please select an option --- </option>
          <option value="buyer">Buyer</option>
          <option value="seller">Seller</option>
        </select>

        <h5 className="text-right mt-4">
          Already have an account?{" "}
          <a href='/login' className="text-primary">Login here</a>
        </h5>

        <LoaderButton
          block
          size="lg"
          className="treyda-button btn w-50"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          SIGNUP
        </LoaderButton>
      </Form>
    );
  }

  return (
    <LoadingOverlay active={isLoading} spinner text="Please wait...">
      <Navigation />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <img
              src="/images/back2.jpg"
              alt="sign up"
              className=" dk w-100 "
              style={{ height: "100vh" }}
            />
          </div>
          <div className="col-lg-6 p-0">
            <div className="text-center mt-3">
              <h4 className="d-inline font-weight-bolder">Sign Up On </h4>
              <img src="/images/logo.png" alt="logo" width="160" />
            </div>
            <div className="p-5">{renderForm()}</div>
          </div>
        </div>
      </div>

      <Footer />
    </LoadingOverlay>
  );
}
