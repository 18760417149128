import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { signUpReducer, verifySignUpReducer } from './signUp/signUpReducer';
import { loginReducer, getUserReducer } from './login/loginReducer';


const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  getUserReducer,
  signUpReducer,
  user: loginReducer,
  verifySignUpReducer,
});

export default persistReducer(persistConfig, rootReducer);