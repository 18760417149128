export const appConstants = {
    SIGN_UP: 'SIGN_UP',
    VERIFY_SIGN_UP: 'VERIFY_SIGN_UP',
    LOGIN: 'LOGIN',
    GET_USER: 'GET_USER',
    LIST_BANKS: 'LIST_BANKS',
    VERIFY_BANK: 'VERIFY_BANK',
    LIST_BILLS: 'LIST_BILLS',
    GET_BILLS_CATEGORIES: 'GET_BILLS_CATEGORIES',
    INITIATE_TRANSACTION: 'INITIATE_TRANSACTION'
}