import React, { useEffect, useState } from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Router, Switch, Route } from "react-router-dom";
import { BuyerHomepage } from "./pages/Homepage/BuyerHomepage";
import { createBrowserHistory } from "history";
import { UserTypeModal } from "./components/UserTypeModal";
import { SellerHomepage } from "./pages/Homepage/SelletHomepage";
import Signup from "./pages/Auth/SignUp";
import { Terms } from "./pages/Policies/Terms";
import { Ethics } from "./pages/Policies/Ethics";
import { Privacy } from "./pages/Policies/Privacy";
import { Cookies } from "./pages/Policies/Cookies";
import { Login } from "./pages/Auth/Login";
import { VerifyEmail } from "./pages/Auth/VerifyEmail";
import SellerRoot from "./pages/Seller/SellerRoot";
import BuyerRoot from "./pages/Buyer/BuyerRoot";


export const history = createBrowserHistory({ forceRefresh: true });

const userType = localStorage.getItem("userType");

function App() {
  const [show, setShow] = useState(false);
  console.log(show);

  useEffect(() => {
    if (userType === null) {
      setShow(true);
    }
  }, [userType]);

  return (
    <div>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={BuyerHomepage} />
          <Route path="/home-seller" exact component={SellerHomepage} />
          <Route path="/sign-up" exact component={Signup} />
          <Route path="/verify-email" exact component={VerifyEmail} />
          <Route path="/login" exact component={Login} />
          <Route path="/t&c" exact component={Terms} />
          <Route path="/code-of-ethics" exact component={Ethics} />
          <Route path="/privacy-policy" exact component={Privacy} />
          <Route path="/cookies-policy" exact component={Cookies} />
          <Route path="/seller" component={SellerRoot} />
          <Route path="/buyer" component={BuyerRoot} />
        </Switch>
      </Router>
      {show && <UserTypeModal />}

    </div>
  );
}

export default App;
