import React, { createRef, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LoaderButton from "../../../components/LoaderButton";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { createSeller } from "../../../services/onboarding/onboardingService";
import { onError, onSuccess } from "../../../libs/errorLib";
import { history } from "../../../App";

export const StepOne = () => {
    const ref = createRef()
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({
        postal_code: "",
        country: "",
        phone_number: "",
        mobile: "",
        email: "",
        city: "",
        ceo_name: "",
        sector: "",
        legal_entity_name: "",
        legal_form: "",
    });
    console.log(fields);

    function handleFieldChange(e) {
        const { name, value } = e.target;
        setFields((fields) => ({ ...fields, [name]: value }));
       
    var forms = document.querySelectorAll(".form"),
    inputs = [];
for (var i = forms.length; i--;) {
    var els = forms[i].querySelectorAll("input");
    console.log(els, 'shs')
    for (var j = els.length; j--;) {
        if (els[j].type != "button" && els[j].type != "submit") {
            inputs.push(els[j]);
            els[j].addEventListener("input", false);
        }
    }
}

    }


// function blur() {
//     const regex = /\w/;

//     // Check if string contians numbers
//     const doesItHaveNumber = regex.test(fields.ceo_name);
//     if (doesItHaveNumber === true) {
//         setProgress(progress + 2.5)
//     }
// }

// console.log(doesItHaveNumber); // true
//     if (
//       fields.ceo_name === "" ||
//       fields.city === "" ||
//       fields.country === "" ||
//       fields.company_type === "" ||
//       fields.postal_code === "" ||
//       fields.mobile === "" ||
//       fields.phone_number === "" ||
//       fields.email  === ''
//     ) {
//       setProgress(progress + 2.5);
//     }
//     else{
//         return false;
//     }
//   }


const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let response;
    response = await createSeller(fields);
    setIsLoading(false);
    if (response.ok) {
        onSuccess(`${response?.data.response_message || "Successful"}`);
        history.push("/onboarding/seller/step-two");
    } else {
        onError(`${response?.data.response_message || "Something went wrong"}`);
        setIsLoading(false);
    }
};



const getValue = () => {
    if(fields.legal_entity_name) {
       setProgress(progress += 2.5) 
    }
}
useEffect(()=>{
    getValue()
    }, [])

return (
    <div className="container">
        <div className="row">
            <div className="col-lg-9 mx-auto">
                <div className="box p-3 mb-5">
                    <Form onSubmit={handleSubmit} name='form'>
                        <div className="container p-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Commpany Name
                    </p>
                                    <input
                                        ref={ref}
                                        className="w-100"
                                        type="text"
                                        name="legal_entity_name"
                                        value={fields.legal_entity_name}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your company name"

                                    />
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        CEO Name
                    </p>
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="ceo_name"
                                        value={fields.ceo_name}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your CEO name"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Legal Form
                    </p>
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="legal_form"
                                        value={fields.legal_form}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your legal form"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Company Sector
                    </p>
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="sector"
                                        value={fields.sector}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your company sector"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Company Phone Number
                    </p>
                                    <input
                                        className="w-100"
                                        type="number"
                                        name="phone_number"
                                        value={fields.phone_number}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your company phone number"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Commpany Mobile
                    </p>
                                    <input
                                        className="w-100"
                                        type="number"
                                        name="mobile"
                                        value={fields.mobile}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your Company Mobile"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Company Email
                    </p>
                                    <input
                                        className="w-100"
                                        type="email"
                                        name="email"
                                        value={fields.email}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your company email"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Postal Code
                    </p>
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="postal_code"
                                        value={fields.postal_code}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your company postal code"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Company Country
                    </p>
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="country"
                                        value={fields.country}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your company country"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                                        Company City
                    </p>
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="city"
                                        value={fields.city}
                                        onChange={handleFieldChange}
                                        placeholder="Please enter your company city"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <LoaderButton
                                block
                                size="lg"
                                className="treyda-button btn w-50"
                                type="submit"
                            >
                                SAVE CHANGES
                </LoaderButton>
                        </div>
                    </Form>
                </div>
            </div>

            <div className="col-lg-2">
                <h5 className="font-weight-bolder">TRUST SCORE</h5>
                <CircularProgressbar value={progress} text={`${progress}%`} />;
        </div>
        </div>
    </div>
);
};
