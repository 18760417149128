import React from "react";
import Button from "react-bootstrap/Button";
//import { BsArrowRepeat } from "react-icons/bs";
import "../css/loaderButton.css";

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <div className="text-center mt-4">
      <button
        disabled={disabled || isLoading}
        className={`LoaderButton ${className}`}
        {...props}
      >
        {/* {isLoading && <BsArrowRepeat className="spinning" />} */}
        {props.children}
      </button>
    </div>
  );
}
