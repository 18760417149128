import React from "react";
import { useSelector } from "react-redux";
import { AuthenticatedSeller } from "../../../layout/AuthenticatedSeller";
import { data } from "../../Homepage/LatestProducts";

function Dashboard(props) {
  const user = useSelector(state => state.user.data);

  return (
    <>
      <AuthenticatedSeller {...props}>
        <div className="container-fluid p-0">
          <div className="row mt-5">
            <div className="col-lg-6">
              <ol class="breadcrumb bg-dark">
                <li class="breadcrumb-item">
                  <a href="#">Dashboard</a>
                </li>
                <li class="breadcrumb-item text-white">Welcome, {user.first_name} {user.last_name}</li>
              </ol>
            </div>

            <div className='col-lg-6'>
              <input type='search' className='w-100 pl-3 pr-3' placeholder='Search' />
            </div>
          </div>
        </div>

        <h4 className="font-weight-bolder">MATCHES</h4>

        <div className="container-fluid p-0">
          <div className="row">
            {data.map((res, i) => {
              return (
                <div
                  className="col-lg-2 col-sm-12 col-xs-12 col-md-12 text-center p-3"
                  key={i}
                  data-aos="fade-up"
                >
                  <div className="p-3">
                    <img
                      src={res.image}
                      alt={res.name}
                      height="100"
                      width="100"
                    />
                    <h4 className="font-weight-bolder ">{res.name}</h4>

                    <div className="row">
                      <div className="col-lg-4 col-sm-4 col-xs-4 text-left">
                        <small className=" d-block">Supplier:</small>
                        <small className=" d-block">In stock:</small>
                        <small className="d-block">Port:</small>
                        <small className="d-block">Variety:</small>
                        <small className="d-block">Terms:</small>
                      </div>
                      <div className="col-lg-8 col-sm-8 col-xs-8 text-left">
                        <small className="d-block font-weight-bolder">
                          {res.supplier}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.quantitiy}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.port}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.variety}
                        </small>
                        <small className="d-block font-weight-bolder">
                          {res.terms}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="container-fluid p-0 mt-5">
            <div className="row mb-5">
              <div className="col-lg-3">
              <h4 className='font-weight-bolder text-center'>FILTER</h4>
                <div class="card">
                  <article class="card-group-item">
                    <header class="card-header">
                      <h6 class="title">Range input </h6>
                    </header>
                    <div class="filter-content">
                      <div class="card-body">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label>Min</label>
                            <input type="number" class="form-control" id="inputEmail4" placeholder="$0" />
                          </div>
                          <div class="form-group col-md-6 text-right">
                            <label>Max</label>
                            <input type="number" class="form-control" placeholder="$1,0000" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="card-group-item">
                    <header class="card-header">
                      <h6 class="title">By Products</h6>
                    </header>
                    <div class="filter-content">
                      <div class="card-body">
                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">52</span>
                          <input type="checkbox" class="custom-control-input" id="Check1" />
                          <label class="custom-control-label" for="Check1">Mango</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">132</span>
                          <input type="checkbox" class="custom-control-input" id="Check2" />
                          <label class="custom-control-label" for="Check2">Cooca</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">17</span>
                          <input type="checkbox" class="custom-control-input" id="Check3" />
                          <label class="custom-control-label" for="Check3">Cassava</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">7</span>
                          <input type="checkbox" class="custom-control-input" id="Check4" />
                          <label class="custom-control-label" for="Check4">Cashew</label>
                        </div>
                      </div>
                    </div>
                  </article>

                  <article class="card-group-item">
                    <header class="card-header">
                      <h6 class="title">By Incoterms </h6>
                    </header>
                    <div class="filter-content">
                      <div class="card-body">
                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">52</span>
                          <input type="checkbox" class="custom-control-input" id="Check1" />
                          <label class="custom-control-label" for="Check1">FOB</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">132</span>
                          <input type="checkbox" class="custom-control-input" id="Check2" />
                          <label class="custom-control-label" for="Check2">EXW</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">17</span>
                          <input type="checkbox" class="custom-control-input" id="Check3" />
                          <label class="custom-control-label" for="Check3">FCA</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                          <span class="float-right badge badge-light round">7</span>
                          <input type="checkbox" class="custom-control-input" id="Check4" />
                          <label class="custom-control-label" for="Check4">FAS</label>
                        </div>
                      </div>
                    </div>
                  </article>

                  <article class="card-group-item">
                    <header class="card-header">
                      <h6 class="title">Quality </h6>
                    </header>
                    <div class="filter-content">
                      <div class="card-body">
                        <div class="custom-control custom-checkbox">
                          
                          <input type="checkbox" class="custom-control-input" id="Check1" />
                          <label class="custom-control-label" for="Check1">SGS QUALITY CERTIFICATE</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                         
                          <input type="checkbox" class="custom-control-input" id="Check2" />
                          <label class="custom-control-label" for="Check2">HAACP</label>
                        </div>

                        <div class="custom-control custom-checkbox">
                          
                          <input type="checkbox" class="custom-control-input" id="Check3" />
                          <label class="custom-control-label" for="Check3">ISO 22001</label>
                        </div>

                  
                      </div>
                    </div>
                  </article>
                </div>


              </div>
              <div className="col-lg-9">
                <h4 className='font-weight-bolder text-center'>OUR OFFERINGS</h4>
                <div className="row">
                  {data.map((res, i) => {
                    return (
                      <div
                        className="col-lg-4 col-sm-12 col-xs-12 col-md-12 text-center p-3"
                        key={i}
                        data-aos="fade-up"
                      >
                        <div className="p-3 pap">
                          <img src={res.image} alt={res.name} height="100" />
                          <h4 className="font-weight-bolder ">{res.name}</h4>
                          {/* <h6>{res.company}</h6> */}
                          <p style={{ fontSize: "13px" }}>{res.description}</p>
                          <div className="row">
                            <div className="col-lg-4 col-sm-4 col-xs-4 text-left">
                              <small className=" d-block">Supplier:</small>
                              <small className=" d-block">In stock:</small>
                              <small className="d-block">Port:</small>
                              <small className="d-block">Variety:</small>
                              <small className="d-block">Terms:</small>
                            </div>
                            <div className="col-lg-8 col-sm-8 col-xs-8 text-left">
                              <small className="d-block font-weight-bolder">
                                {res.supplier}
                              </small>
                              <small className="d-block font-weight-bolder">
                                {res.quantitiy}
                              </small>
                              <small className="d-block font-weight-bolder">
                                {res.port}
                              </small>
                              <small className="d-block font-weight-bolder">
                                {res.variety}
                              </small>
                              <small className="d-block font-weight-bolder">
                                {res.terms}
                              </small>
                            </div>
                          </div>

                          <p
                            style={{ fontSize: "13px" }}
                            className="font-weight-bolder mt-3"
                          >
                            {res.price}/Metric Tonne
                        </p>

                          <button className="btn treyda-button w-50 font-weight-bolder">
                            View Details
                        </button>
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-lg-12 text-center mt-3">
                    <button className="btn treyda-button w-50 font-weight-bolder">
                      SEE ALL OFFERINGS
                  </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </AuthenticatedSeller>
    </>
  );
}

export default Dashboard;
