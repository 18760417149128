import React, { useState } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { useDispatch } from "react-redux";
import { login } from "../../services/login/login";
import { loginAction } from "../../redux/actions/login/loginAction";
import { onError, onSuccess } from "../../libs/errorLib";
import { history } from "../../App";

export const Login = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    email: "",
    password: "",
    account_type: "",
  });

  function handleFieldChange(e) {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  }
  console.log(fields);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let response;
    response = await login(fields);
    setIsLoading(false);
    if (response.ok) {
      dispatch(loginAction(response.data.data));
      onSuccess(`${response?.data.response_message || "Successful"}`);
      sessionStorage.setItem("token", response.data.data.token);

      if (
        response.data.data.have_seller_account === true &&
        response.data.data.seller_account_active === null
      ) {
        history.push("/seller/onboarding");
      } else if (
        response.data.data.have_buyer_account === true &&
        response.data.data.seller_buyer_active === null
      ) {
        history.push("/buyer/onboarding");
      } else {
        history.push(`${localStorage.getItem("userType")}/dashboard`);
      }
    } else {
      onError(`${response?.data.response_message || "Something went wrong"}`);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
            <img
              src="/images/back3.jpg"
              alt="sign up"
              className="dk w-100"
              style={{ height: "100vh" }}
            />
          </div>
          <div className="col-lg-6 p-0">
            <div className="text-center mt-3">
              <h4 className="d-inline font-weight-bolder">Login to</h4>
              <img src="/images/logo.png" alt="logo" width="160" />
            </div>
            <div className="p-5">
              <Form onSubmit={handleSubmit}>
                <p className="text-left m-0 mt-3 mb-2 text-dark">Email</p>
                <input
                  className="w-100"
                  type="email"
                  name="email"
                  value={fields.email}
                  onChange={handleFieldChange}
                  placeholder="Please enter your email address"
                />
                <p className="text-left m-0 mt-3 mb-2 text-dark">Password</p>
                <input
                  className="w-100"
                  type="password"
                  name="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                  placeholder="Please enter your password"
                />

                <p className="text-left m-0 mt-4 mb-2 text-dark">
                  Sign in as a:
                </p>
                <select
                  className="w-100"
                  value={fields.account_type}
                  onChange={handleFieldChange}
                  name="account_type"
                >
                  <option value="">---Please select an option --- </option>
                  <option value="buyer">Buyer</option>
                  <option value="seller">Seller</option>
                </select>

                <h5 className="text-right mt-4">
                  Do not have an account?{" "}
                  <a href='/sign-up' className="text-primary">Register here</a>
                </h5>
                <h5 className="text-right">
                  <a href='/sign-up' className="text-primary">Forgot password?</a>
                </h5>
                <div className="form-group">
                  <LoaderButton
                    block
                    size="lg"
                    className="treyda-button btn w-50"
                    type="submit"
                  >
                    SIGN IN
                  </LoaderButton>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
