import React from 'react'
import { AuthenticatedSeller } from '../../../layout/AuthenticatedSeller';
import { Stepper, Step } from 'react-form-stepper';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { useSelector } from 'react-redux';

export const SellerOnboarding = () => {
    const match = useRouteMatch();
    const user = useSelector(state=>state.user.data)

    return (
        <AuthenticatedSeller>
            {/* <Stepper activeStep={1}>
                <Step onClick={() => history.push(`${match.url}`)} label="Company Information" />
                <Step onClick={() => history.push(`${match.url}/step-two`)} label="Children Step 2" />
                <Step label="Management" />
            </Stepper> */}
            <ol class="breadcrumb bg-dark mt-5">
                <li class="breadcrumb-item">
                    <a href="#">Seller Onboarding</a>
                </li>
                <li class="breadcrumb-item text-white">{user.first_name} {user.last_name}</li>
            </ol>
            <Switch>
                <Route path={`${match.url}/step-two`} >
                    <div >
                        <h4 className='font-weight-bolder text-center m-3'>Company Information Two</h4>
                        <StepTwo />
                    </div>
                </Route>
                <Route path={`${match.url}/step-three`} >
                    <div >
                    <h4 className='font-weight-bolder text-center m-3'>Company Information Three</h4>
                        <StepThree />
                    </div>
                </Route>
                <Route path={`${match.url}/step-four`} >
                    <div >
                    <h4 className='font-weight-bolder text-center m-3'>Management Information and Files Upload</h4>
                        <StepFour/>
                    </div>
                </Route>


                <Route path={`${match.url}`} >
                    <div>
                        <h4 className='font-weight-bolder text-center mt-5'>Company Information</h4>
                        <StepOne />
                    </div>
                </Route>
            </Switch>
        </AuthenticatedSeller>
    )
}
