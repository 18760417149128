import React from "react";
import { Footer } from "../../components/Footer";
import { Navigation } from "../../components/Navigation";

export const Ethics = () => {
  return (
    <div style={{ background: "#FEFFF9" }}>
      <Navigation />
      <div className="container">
          <div className="col-lg-9 mx-auto">
            <img src="/images/ethics/page_001.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/ethics/page_002.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/ethics/page_003.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/ethics/page_004.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/ethics/page_005.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/ethics/page_006.jpg" alt="terms1" className='img-fluid'/>
            
          </div>
      </div>
      <Footer/>
    </div>
  );
};
