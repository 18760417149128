import React from 'react';
import { Switch, Route } from 'react-router-dom';
import '../../css/onboarding.css';
import Dashboard from './Dashboard/Dashboard';

export default function BuyerRoot({ match }) {
    return (
        <div>
            <Switch>
                <Route exact path={`${match.url}/`} />
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
            </Switch>
        </div>

    );
}