import React, { useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { useDispatch } from 'react-redux';
import { history } from '../../App';
import { onError, onSuccess } from '../../libs/errorLib';
import { verifySignUpAction } from '../../redux/actions/signUp/signUpAction';
import { sendOtp, verifySignUp } from '../../services/signUp/signUp';
import { Navigation } from '../../components/Navigation';
import { Footer } from '../../components/Footer';
import LoadingOverlay from 'react-loading-overlay';

export const VerifyEmail = () => {
    const action = 'email';
    const dispatch = useDispatch();
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState(10);

    const padTime = time => {
        return String(time).length === 1 ? `0${time}` : `${time}`;
    };

    const format = time => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${padTime(seconds)}`;
    };


    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(c => c - 1), 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [counter]);

    function validateConfirmationForm() {
        return token.length > 0;
    };

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        let response;
        response = await verifySignUp(token, action);
        setIsLoading(false);
        if (response.ok) {
            dispatch(verifySignUpAction(response.data));
            onSuccess(`${response?.data.response_message || "Successful"}`)
            history.push('/login')
        } else {
            onError(`${response?.data.error || "Something went wrong"}`);
        }
    };

    async function resend(event) {
        event.preventDefault();
        setIsLoading(true);
        let response;
        response = await sendOtp(localStorage.getItem('email'), action);
        setIsLoading(false);
        if (response.ok) {
            onSuccess(`${response?.data.response_message || "Successful"}`)
        } else {
            onError(`${response?.data.response_message || "Something went wrong"}`);
        }
    };

    function renderConfirmationForm() {
        return (
            <Form onSubmit={handleConfirmationSubmit}>
                <p className="text-left m-0 mt-4 mb-2 text-dark">Token</p>
                <input
                    className="w-100"
                    type="text"
                    name="token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    placeholder="Please enter the 6 digits code sent to your email"
                />

                <LoaderButton
                    block
                    size="lg"
                    className="treyda-button btn w-50"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    VERIFY
        </LoaderButton>
            </Form>
        );
    }


    return (
        <LoadingOverlay active={isLoading}
            spinner
            text='Verifying your email... Please wait...'
        >
            <Navigation />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 p-0">
                        <img
                            src="/images/back2.jpg"
                            alt="sign up"
                            className=" dk w-100 "
                            style={{ height: '100vh' }}
                        />
                    </div>
                    <div className="col-lg-6 p-0">
                        <div className="text-center mt-3">
                            <h4 className='d-inline font-weight-bolder'>Complete your SignUp On </h4>
                            <img src="/images/logo.png" alt="logo" width="160" />
                        </div>
                        <div className="p-5">
                            {renderConfirmationForm()}
                        </div>

                        <div className="container">
                            <div className="row">

                                <div className="col text-center">
                                    {counter === 0 ?
                                        <div>
                                            <p> Did not get the SMS?  <span onClick={resend} className='text-primary font-weight-bolder '>Resend</span></p>

                                        </div>
                                        :
                                        <div>
                                            <p>
                                                Resend OTP in: <span className='text-primary font-weight-bolder'>{format(counter)}</span><span>s</span>
                                            </p>

                                        </div>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </LoadingOverlay>
    )
}
