import { appConstants } from "../types";

export const signUpAction = (payload) => {
    return {
        type: appConstants.SIGN_UP,
        payload: payload,
    };
};

export const verifySignUpAction = (payload) => {
    return {
        type: appConstants.VERIFY_SIGN_UP,
        payload: payload,
    };
};
