import { appConstants } from "../../../redux/actions/types";

const initialState = {
    data: [],
    user:[]
};

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case appConstants.LOGIN:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export function getUserReducer(state = initialState, action) {
    switch (action.type) {
        case appConstants.GET_USER:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
};