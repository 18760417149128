import { create } from "apisauce";

const client = create({
    baseURL: "https://backend-api-jz7ri.ondigitalocean.app/api",
});

client.addAsyncRequestTransform(async (request) => {
    const authToken = sessionStorage.getItem("token");
    if (!authToken) {
        return;
    } else {
        request.headers["Authorization"] = `Bearer ${authToken}`;
    }
});

export default client;