import React from "react";
import { Footer } from "../components/Footer";
import "../css/authenticated.css";

export const AuthenticatedSeller = (props) => {
    return (
        <div>
            <nav
                className="navbar navbar-expand-lg navbar-dark fixed-top"
                id="mainNav"
            >
                <a className="navbar-brand" href="/">
                    <img src="/images/logo-white.png" alt="logo" height="60" />
                </a>
                <button
                    className="navbar-toggler navbar-toggler-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav" id="exampleAccordion">
                        <li
                            className="nav-item mr-3"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Dashboard"
                        >
                            <a className="nav-link" href="/dashboard">
                                <i className=" fa-lg fa fa-fw fa-tachometer"></i>
                                <span className="nav-link-text ">Dashboard</span>
                            </a>
                        </li>
                        <li
                            className="nav-item mr-3"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Messages"
                        >
                            <a className="nav-link" href="#">
                                <i className=" fa-lg fa fa-fw fa-comments"></i>
                                <span className="nav-link-text">Messages</span>
                            </a>
                        </li>
                        <li
                            className="nav-item mr-3"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Get Funded"
                        >
                            <a className="nav-link" href="#">
                                <i className="fa-lg fa fa-fw fa-link"></i>
                                <span className="nav-link-text">Matches</span>
                            </a>
                        </li>

                        <li
                            className="nav-item mr-3"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Get Funded"
                        >
                            <a className="nav-link" href="#">
                                <i className="fa-lg fa fa-fw fa-dollar"></i>
                                <span className="nav-link-text">Get funded</span>
                            </a>
                        </li>

                        <li
                            className="nav-item mr-3"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Services"
                        >
                            <a className="nav-link" href="#">
                                <i className="fa-lg fa fa-fw fa-file" ></i>
                                <span className="nav-link-text">Services</span>
                            </a>
                        </li>
                        <li
                            className="nav-item mr-3"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Company Profile"
                        >
                            <a className="nav-link" href="#">
                                <i className="fa-lg fa fa-fw fa-users"></i>
                                <span className="nav-link-text">Company Profile</span>
                            </a>
                        </li>

                        <li
                            className="nav-item mr-3"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Help"
                        >
                            <a className="nav-link" href="#">
                                <i className="fa-lg fa fa-fw fa-question-circle"></i>
                                <span className="nav-link-text">Help</span>
                            </a>
                        </li>

                        <div class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
                                <i className="fa-lg fa fa-fw fa-user-circle"></i>Account</a>
                            <div class="dropdown-menu">
                                <a className="nav-link text-dark font-weight-bolder" href="#">
                                    <i className="fa-lg fa fa-fw fa-wrench"></i>
                                    <span className="nav-l ink-text">Settings</span>
                                </a>
                                <hr />
                                <a className="nav-link text-dark font-weight-bolder" href="#">
                                    <i className="fa-lg fa fa-fw fa-sign-out"></i>
                                    <span className="nav-link-text">Logout</span>
                                </a>

                            </div>
                        </div>



                    </ul>
                </div>
            </nav>

            <div className="content-wrapper">
                <div className="container-fluid">{props.children}</div>
               
                {/* <Footer/> */}
                </div>
                
            
        </div>
    );
};
