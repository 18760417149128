import { appConstants } from "../types";

export const loginAction = (payload) => {
    return {
        type: appConstants.LOGIN,
        payload: payload,
    };
};

export const getUserAction = (payload) => {
    return {
        type: appConstants.GET_USER,
        payload: payload,
    };
};
