import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LoaderButton from "../../../components/LoaderButton";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { updateSeller } from "../../../services/onboarding/onboardingService";
import { onError, onSuccess } from "../../../libs/errorLib";
import { history } from "../../../App";

export const StepTwo = () => {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    number_of_staff: "",
    website: "",
    factory_farm_address: "",
    registration_number: "",
    company_type: "",
    establishment_year: "",
    product_services: "",
    annual_sales_year_one: "",
    annual_sales_year_two: "",
    annual_sales_year_three: "",
  });

  function handleFieldChange(e) {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let response;
    response = await updateSeller(fields);
    setIsLoading(false);
    if (response.ok) {
      onSuccess(`${response?.data.response_message || "Successful"}`);
      history.push("/onboarding/seller/step-three");
    } else {
      onError(`${response?.data.response_message || "Something went wrong"}`);
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-9 mx-auto">
          <div className="box p-3 mb-5">
            <Form onSubmit={handleSubmit}>
              <div className="container p-0">
                <div className="row">
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Staff Strength
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="number_of_staff"
                      value={fields.number_of_staff}
                      onChange={handleFieldChange}
                      placeholder="Please enter your staff strength"
                    />
                  </div>
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Company Website
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="website"
                      value={fields.website}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company website"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Factory farm address
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="factory_farm_address"
                      value={fields.factory_farm_address}
                      onChange={handleFieldChange}
                      placeholder="Please enter your factory farm address"
                    />
                  </div>

                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Product Services
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="product_services"
                      value={fields.product_services}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company product"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Regsitration Number
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="registration_number"
                      value={fields.registration_number}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company regsitration number"
                    />
                  </div>
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Commpany Type
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="company_type"
                      value={fields.company_type}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company type"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Year of establishment
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="establishment_year"
                      value={fields.establishment_year}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company year of estabishment"
                    />
                  </div>

                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Annual sales in year one
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="annual_sales_year_one"
                      value={fields.annual_sales_year_one}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company sales in year one"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Annual sales in year two
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="annual_sales_year_two"
                      value={fields.annual_sales_year_two}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company sales in year two"
                    />
                  </div>
                  <div className="col-lg-6">
                    <p className="text-left m-0 mt-3 mb-2 text-dark">
                      Annual sales in year three
                    </p>
                    <input
                      className="w-100"
                      type="text"
                      name="annual_sales_year_three"
                      value={fields.annual_sales_year_three}
                      onChange={handleFieldChange}
                      placeholder="Please enter your company sales in year three"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <LoaderButton
                  block
                  size="lg"
                  className="treyda-button btn w-50"
                  type="submit"
                >
                  SAVE CHANGES
                </LoaderButton>
              </div>
            </Form>
          </div>
        </div>

        <div className="col-lg-2">
          <h5 className="font-weight-bolder">TRUST SCORE</h5>
          <CircularProgressbar value={progress} text={`${progress}%`} />;
        </div>
      </div>
    </div>
  );
};
