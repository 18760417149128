import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { history } from "../App";

export const UserTypeModal = () => {
  const [user, setUser] = useState("");
  function setUserType(type) {
    localStorage.setItem("userType", type);
    setUser(type);
  }
 // const history = useHistory();

  useEffect(() => {
    if (user === "buyer") {
      history.push("/");
    } else if (user === "seller") {
      history.push("/home-seller");
    }
  }, [user]);

  return (
    <div className="text-center">
      <Modal show={true} size="md" backdrop="static">
        <h4 className="text-center font-weight-bolder pt-3">
          WELCOME TO TREYDA!!!
        </h4>
        <Modal.Body className="p-5">
          <div className="text-center">
            <img src="/images/welcome.png" alt="welcome" style={{width:'auto', height:'270px'}} />
          </div>

          <h5 className="text-center">I want to</h5>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-xs-6  mt-3">
                <button
                  onClick={() => setUserType("buyer")}
                  className="btn btn-lg treyda-button w-100 font-weight-bolder"
                >
                  BUY
                </button>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6  mt-3">
                <button
                  onClick={() => setUserType("seller")}
                  className="btn btn-lg treyda-button w-100 font-weight-bolder"
                >
                  SELL
                </button>
              </div>
              <div className="col-lg-6 mx-auto mt-3">
                <button className="btn btn-lg treyda-button w-100 font-weight-bolder">
                OTHERS
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
