import swal from 'sweetalert';

export function onError(error) {
  console.log(error)
  let message = error

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    message = error.message;
  }

  swal("Oh Oh!", message, "error");
}

export function onSuccess(success) {
  console.log(success)
  swal("Great!", success, "success");
}