import client from "../httpsService";

const signUp = (data) => client.post(`/register`, data);

const sendOtp = (phone_or_email, action) => client.post(`/send_otp`, { phone_or_email, action })

const verifySignUp = (token, action) => client.post(`/verify_otp`, { token, action });

export {
    signUp,
    sendOtp,
    verifySignUp
}