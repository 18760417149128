import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SellerOnboarding } from './SellerOnboarding/SellerOnboarding';
import '../../css/onboarding.css';
import Dashboard from './Dashboard/Dashboard';

export default function SellerRoot({ match }) {
    return (
        <div>
            <Switch>
                <Route exact path={`${match.url}/`} />
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route path={`${match.url}/onboarding`} component={SellerOnboarding} />
            </Switch>
        </div>

    );
}