import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { history } from "../App";
import GoogleTranslate from "./Google";

export const Navigation = () => {
  const [value, setValue] = useState(localStorage.getItem("userType"));

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  function changeUser(e) {
    setValue(e.target.value);
    localStorage.setItem("userType", e.target.value);
    if (localStorage.getItem("userType") === "buyer") {
      history.push("/");
    } else if (localStorage.getItem("userType") === "seller") {
      history.push("/home-seller");
    }
  }

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-black"
        style={{ background: "#000" }}
      >
        <a className="navbar-brand" href="/">
          <img
            src="/images/logo-white.png"
            className="ml-lg-5 mr-5"
            alt="logo"
            width="150"
            height="60"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active mr-2">
              <a className="nav-link font-weight-bolder" href="/">
                Home <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item mr-2">
              <a className="nav-link font-weight-bolder" href="/">
                Services
              </a>
            </li>
            <li className="nav-item mr-2">
              <a className="nav-link font-weight-bolder" href="/">
                Market Insights
              </a>
            </li>
            <li className="nav-item mr-2">
              <a className="nav-link font-weight-bolder" href="/">
                Information
              </a>
            </li>
            <li className="nav-item mr-2">
              <a className="nav-link font-weight-bolder" href="/">
                Events
              </a>
            </li>
            <li className="nav-item mr-2">
              <a className="nav-link font-weight-bolder" href="/">
                Contact Us
              </a>
            </li>
            <li className="nav-item mr-2">
              <select
                onChange={changeUser}
                value={value}
                className="w-100 p-1"
                style={{ fontSize: "17px" }}
              >
                <option value="buyer">Buyer</option>
                <option value="seller">Seller</option>
              </select>
            </li>
          </ul>
          {/* <a className="nav-link text-decoration-none font-weight-bolder mr-5" href='/sign-up'>Sign In</a> */}
          <a
            className="nav-link text-decoration-none font-weight-bolder mr-5"
            href="#"
            onClick={handleShow}
          >
            Join Waiting List
          </a>

          <GoogleTranslate />
        </div>
      </nav>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Join Waiting List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            className="navbar-brand"
            href="https://docs.google.com/forms/d/e/1FAIpQLScVhHkdxILkNiwnyGc1ee1-YD8Z3bpSXJcThJq-4Y0j_o3jbw/viewform"
          >
            <img
              src="/images/ads1.png"
              className="ml-lg-5 mr-5"
              alt="logo"
              width="85%"
              height="90%"
            />
          </a>
          <a
            className="navbar-brand"
            href="https://docs.google.com/forms/d/e/1FAIpQLScVhHkdxILkNiwnyGc1ee1-YD8Z3bpSXJcThJq-4Y0j_o3jbw/viewform"
          >
            <img
              src="/images/ads2.png"
              className="ml-lg-5 mr-5"
              alt="logo"
              width="85%"
              height="90%"
            />
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <a
            className="navbar-brand"
            href="https://docs.google.com/forms/d/e/1FAIpQLScVhHkdxILkNiwnyGc1ee1-YD8Z3bpSXJcThJq-4Y0j_o3jbw/viewform"
          >
            <Button variant="primary">
              Okay
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
