import { appConstants } from "../../../redux/actions/types";

const initialState = {
    data: [],
    data2:[]
};

export function signUpReducer(state = initialState, action) {
    switch (action.type) {
        case appConstants.SIGN_UP:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export function verifySignUpReducer(state = initialState, action) {
    switch (action.type) {
        case appConstants.VERIFY_SIGN_UP:
            return {
                ...state,
                data2: action.payload
            };
        default:
            return state;
    }
};