export const data = [
  {
    image: "/images/green.jpg",
    company: "Treyda",
    name: "Green Pepper",
    description: "Fresh green pepper from Ghana.",
    price: "From USD 5.00 - USD 10.00",
    supplier: "Available for members",
    quantitiy: "250mt",
    port: "Tema",
    variety: "Long Cayenne",
    terms: "FOB/CIF",
  },
  {
    image: "/images/tomato.jpg",
    company: "Treyda",
    name: "Tomato",
    description: "Fresh tomatoes from Nigeria",
    price: "From USD 5.00 - USD 10.00",
    supplier: "Available for members",
    quantitiy: "400mt",
    port: "Lagos",
    variety: "Roma",
    terms: "FOB/CIF",
  },
  {
    image: "/images/avocado.jpg",
    company: "Treyda",
    name: "Avocado",
    description: "Fresh avocados from Kenya",
    price: "From USD 5.00 - USD 10.00",
    supplier: "Available for members",
    quantitiy: "100mt",
    port: "Mombasa",
    variety: "Hass",
    terms: "FOB/CIF",
  },
  {
    image: "/images/green.jpg",
    company: "Treyda",
    name: "Green Pepper",
    description: "Fresh green pepper from Ghana.",
    price: "From USD 5.00 - USD 10.00",
    supplier: "Available for members",
    quantitiy: "250mt",
    port: "Tema",
    variety: "Long Cayenne",
    terms: "FOB/CIF",
  },
  {
    image: "/images/tomato.jpg",
    company: "Treyda",
    name: "Tomato",
    description: "Fresh tomatoes from Nigeria",
    price: "From USD 5.00 - USD 10.00",
    supplier: "Available for members",
    quantitiy: "400mt",
    port: "Lagos",
    variety: "Roma",
    terms: "FOB/CIF",
  },
  {
    image: "/images/avocado.jpg",
    company: "Treyda",
    name: "Avocado",
    description: "Fresh avocados from Kenya",
    price: "From USD 5.00 - USD 10.00",
    supplier: "Available for members",
    quantitiy: "100mt",
    port: "Mombasa",
    variety: "Hass",
    terms: "FOB/CIF",
  },
];

export const categories = [
  {
    image: "/images/fruit.jpg",
    name: "Fruits",
  },
  {
    image: "/images/banner3.jpg",
    name: "Vegetables",
  },
  {
    image: "/images/cocoa.jpg",
    name: "Cocoa",
  },
  {
    image: "/images/coffee.jpg",
    name: "Coffee",
  },
  {
    image: "/images/cereal.jpg",
    name: "Cereals",
  },
  {
    image: "/images/sugar.jpg",
    name: "Sugar",
  },
];
