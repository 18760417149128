import React, { useState } from "react";
import { CloudinaryContext } from "cloudinary-react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LoaderButton from "../../../components/LoaderButton";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { updateSeller } from "../../../services/onboarding/onboardingService";
import { onError, onSuccess } from "../../../libs/errorLib";
import { history } from "../../../App";
import { openUploadWidget } from "../../../utils/cloudinary/cloudinaryService";

export const StepFour = () => {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    management_member_one_name: "",
    management_member_one_phone_number: "",
    management_member_one_email: "",
    management_member_one_address: "",
    management_member_one_passport: "",
    latest_annual_report: "",
    export_certification: "",
    statutes: "",
    bank_statement: "",
    commercial_registry: "",
  });

  function handleFieldChange(e) {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let response;
    response = await updateSeller(fields);
    setIsLoading(false);
    if (response.ok) {
      //  onSuccess(`${response?.data.response_message || "Successful"}`);
      history.push("/onboarding/seller/step-four");
    } else {
      // onError(`${response?.data.response_message || "Something went wrong"}`);
      setIsLoading(false);
    }
  };
  console.log(fields);
  // For cloudinary upload
  const beginUpload1 = (tag) => {
    //console.log(tag)
    const uploadOptions = {
      cloudName: "duaczcsnv",
      tags: [tag],
      uploadPreset: "Upload",
    };
    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {
        if (photos.event === "success") {
          console.log(photos.info, "info");
          setFields((fields) => ({
            ...fields,
            latest_annual_report: photos.info.secure_url,
          }));
        }
      } else {
        console.log(error);
      }
    });
  };

  const beginUpload2 = (tag) => {
    //console.log(tag)
    const uploadOptions = {
      cloudName: "duaczcsnv",
      tags: [tag],
      uploadPreset: "Upload",
    };
    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {
        if (photos.event === "success") {
          console.log(photos.info, "info");
          setFields((fields) => ({
            ...fields,
            export_certification: photos.info.secure_url,
          }));
        }
      } else {
        console.log(error);
      }
    });
  };

  const beginUpload3 = (tag) => {
    //console.log(tag)
    const uploadOptions = {
      cloudName: "duaczcsnv",
      tags: [tag],
      uploadPreset: "Upload",
    };
    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {
        if (photos.event === "success") {
          console.log(photos.info, "info");
          setFields((fields) => ({
            ...fields,
            statutes: photos.info.secure_url,
          }));
        }
      } else {
        console.log(error);
      }
    });
  };

  const beginUpload4 = (tag) => {
    //console.log(tag)
    const uploadOptions = {
      cloudName: "duaczcsnv",
      tags: [tag],
      uploadPreset: "Upload",
    };
    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {
        if (photos.event === "success") {
          console.log(photos.info, "info");
          setFields((fields) => ({
            ...fields,
            bank_statement: photos.info.secure_url,
          }));
        }
      } else {
        console.log(error);
      }
    });
  };

  const beginUpload5 = (tag) => {
    //console.log(tag)
    const uploadOptions = {
      cloudName: "duaczcsnv",
      tags: [tag],
      uploadPreset: "Upload",
    };
    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {
        if (photos.event === "success") {
          console.log(photos.info, "info");
          setFields((fields) => ({
            ...fields,
            commercial_registry: photos.info.secure_url,
          }));
        }
      } else {
        console.log(error);
      }
    });
  };

  return (
    <CloudinaryContext cloudName="duaczcsnv">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 mx-auto">
            <div className="box p-3 mb-5">
              <Form onSubmit={handleSubmit}>
                <div className="container p-0">
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Management member one name
                      </p>
                      <input
                        className="w-100"
                        type="text"
                        name="management_member_one_name"
                        value={fields.management_member_one_name}
                        onChange={handleFieldChange}
                        placeholder="Please enter your management member one name"
                      />
                    </div>
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Management member one phone number
                      </p>
                      <input
                        className="w-100"
                        type="number"
                        name="management_member_one_phone_number"
                        value={fields.management_member_one_phone_number}
                        onChange={handleFieldChange}
                        placeholder="Please enter your management member one phone number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Management member one email
                      </p>
                      <input
                        className="w-100"
                        type="email"
                        name="management_member_one_email"
                        value={fields.management_member_one_email}
                        onChange={handleFieldChange}
                        placeholder="Please enter your management member one email"
                      />
                    </div>

                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Management member one address
                      </p>
                      <input
                        className="w-100"
                        type="text"
                        name="management_member_one_address"
                        value={fields.management_member_one_address}
                        onChange={handleFieldChange}
                        placeholder="Please enter your management member one address"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Director one passport
                      </p>
                      <input
                        className="w-100"
                        type="text"
                        name="director_one_passport"
                        value={fields.director_one_passport}
                        onChange={handleFieldChange}
                        placeholder="Please enter your director one passport"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Latest annual report
                      </p>
                      <button
                        className="btn treyda-button"
                        onClick={() => beginUpload1()}
                      >
                        Click to upload your annual report
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Export certification
                      </p>
                      <button
                        className="btn treyda-button"
                        onClick={() => beginUpload2()}
                      >
                        Click to upload your export certification
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Statuses
                      </p>
                      <button
                        className="btn treyda-button"
                        onClick={() => beginUpload3()}
                      >
                        Click to upload your statuses
                      </button>
                    </div>

                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Bank Statement
                      </p>
                      <button
                        className="btn treyda-button"
                        onClick={() => beginUpload4()}
                      >
                        Click to upload your bank statement
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <p className="text-left m-0 mt-3 mb-2 text-dark">
                        Commercial Registry
                      </p>
                      <button
                        className="btn treyda-button"
                        onClick={() => beginUpload5()}
                      >
                        Click to upload your commercial registry
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <LoaderButton
                    block
                    size="lg"
                    className="treyda-button btn w-50"
                    type="submit"
                  >
                    SAVE CHANGES
                  </LoaderButton>
                </div>
              </Form>
            </div>
          </div>

          <div className="col-lg-2">
            <h5 className="font-weight-bolder">TRUST SCORE</h5>
            <CircularProgressbar value={progress} text={`${progress}%`} />;
          </div>
        </div>
      </div>
    </CloudinaryContext>
  );
};
