import React from "react";
import { Footer } from "../../components/Footer";
import { Navigation } from "../../components/Navigation";

export const Terms = () => {
  return (
    <div style={{ background: "#FEFFF9" }}>
      <Navigation />
      <div className="container">
          <div className="col-lg-9 mx-auto">
            <img src="/images/terms/page_001.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_002.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_003.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_004.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_005.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_006.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_007.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_008.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_009.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_010.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_011.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_012.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_013.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_014.jpg" alt="terms1" className='img-fluid'/>
            <img src="/images/terms/page_015.jpg" alt="terms1" className='img-fluid'/>
          </div>
      </div>
      <Footer/>
    </div>
  );
};
